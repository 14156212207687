<template>
  <div class="grid">
    <WidgetHeading is-first is-big :content="title" class="title">
      <template #right>
        <Filters
          :query-key="FILTER_KEY"
          :options="[
            {
              label: t('all'),
              ariaLabel: t('a11y.all-artists'),
              value: undefined
            },
            ...dateOptions
          ]"
        />
      </template>
    </WidgetHeading>
    <ul class="tiles" role="list">
      <li v-for="artist in artistsFiltered" :key="artist.id">
        <ArtistCard :artist="artist" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { uniqBy } from 'lodash-es'

import { ArtistWithNearestEvent } from '@/service/__generated-api'

const props = defineProps<{
  title: string
  artists: ArtistWithNearestEvent[]
}>()

const { t } = useI18n()
const FILTER_KEY = 'date'

const dateOptions = computed(() =>
  uniqBy(
    props.artists
      .filter(({ nearest_event }) => nearest_event?.fixed_date)
      .map(({ nearest_event }) =>
        getDateObject(nearest_event.fixed_date, false)
      )
      .sort((a, b) => (a.isAfter(b) ? 1 : -1))
      .map(date => {
        const label = formatDateObject(date, 'D.MM')

        return {
          label,
          value: label,
          ariaLabel: formatDateObject(date, 'dddd D.MM')
        }
      }),
    'value'
  )
)

const route = useRoute()
const selectedDate = computed(() => route.query[FILTER_KEY])

const artistsFiltered = computed(() =>
  selectedDate.value
    ? props.artists.filter(
        ({ nearest_event }) =>
          nearest_event?.fixed_date &&
          formatDate(nearest_event.fixed_date, 'D.MM', false) ===
            route.query[FILTER_KEY]
      )
    : props.artists
)
</script>

<style lang="scss" scoped>
.title,
.tiles {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
}

.title {
  margin-bottom: rem(16px);

  @include media-up(lg) {
    margin-bottom: rem(24px);
  }
}

.tiles {
  @include reset-list;
  @include tiles(
    (
      xs: 2 rem(9px),
      md: 3,
      lg: 4
    )
  );
}
</style>
