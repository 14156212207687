<template>
  <BaseLink
    class="artist-card"
    :class="[`artist-card--${color}`, big && 'artist-card--big']"
    :link="{ anchor_type: 'artist', slug: artist.slug }"
  >
    <OutlineText
      v-if="artist.nearest_event?.fixed_date"
      :text="dateFormatted"
      stroke-width="1.5"
      class="background"
    />
    <Arrows class="arrows" />
    <div class="content">
      <h3 class="title">
        <span class="artist-name" role="text" :aria-label="artist.title">
          {{ artist.title }}
        </span>
        <span v-if="artist.country" class="country t-caption-small">{{
          artist.country
        }}</span>
      </h3>
      <div v-if="artist.image" class="image-container">
        <BaseImage :image="artist.image" v-bind="imageSizes" />
      </div>
      <EventDescription
        :date="artist.nearest_event?.fixed_date"
        :place="artist.nearest_event?.place?.name"
        :size="big ? 'big' : 'medium'"
        :timezoned="false"
      />
    </div>
  </BaseLink>
</template>

<script lang="ts" setup>
import { ArtistWithNearestEvent } from '@/service/__generated-api'

const props = defineProps<{ artist: ArtistWithNearestEvent; big?: boolean }>()

const dateFormatted = computed(() =>
  formatDate(props.artist.nearest_event?.fixed_date, 'D.MM', false)
)

const imageSizes = computed(() =>
  getImageSizes(
    props.big
      ? {
          xs: { w: 140, h: 78 },
          md: { w: 296, h: 159 },
          lg: { w: 376, h: 211 }
        }
      : {
          xs: { w: 140, h: 78 },
          md: { w: 192, h: 103 },
          lg: { w: 227, h: 151 }
        }
  )
)

const color = computed(() => props.artist.nearest_event?.place?.color ?? 'pink')
</script>

<style lang="scss" scoped>
.artist-card {
  @include focus-visible;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  @include media-up(md) {
    min-height: rem(260px);

    &--big {
      min-height: rem(400px);
    }
  }

  @include media-up(lg) {
    min-height: rem(372px);

    &--big {
      min-height: rem(504px);
    }
  }

  &:hover,
  &:focus-visible {
    :deep(.image) {
      transform: scale(1.1);
    }

    .arrows :deep(path) {
      animation-name: move;
    }
  }

  @each $key, $value in $places-colors {
    &--#{'' + $key} {
      background-color: $value;

      .arrows {
        color: $value;
      }
    }
  }
}

.artist-card :deep(.image) {
  transition:
    transform $transition,
    opacity $transition;
}

.content {
  padding: 0 rem(12px) rem(12px);
  margin-top: auto;

  @include media-up(md) {
    padding: 0 rem(16px) rem(16px);

    .artist-card--big & {
      padding: 0 rem(24px) rem(24px);
    }
  }

  @include media-up(lg) {
    padding: 0 rem(24px) rem(24px);

    .artist-card--big & {
      padding: 0 rem(32px) rem(32px);
    }
  }
}

.arrows {
  @include size(rem(24px));
  position: relative;
  display: block;
  margin-bottom: rem(20px);
  margin-left: auto;
  overflow: hidden;
  container-type: size;

  @include media-up(md) {
    @include size(rem(31.32px));
    margin-bottom: rem(13px);

    .artist-card--big & {
      @include size(rem(48px));
    }
  }

  @include media-up(lg) {
    @include size(rem(39.899px));
    margin-bottom: rem(5.1px);

    .artist-card--big & {
      @include size(rem(54px));
      margin-bottom: rem(3px);
    }
  }
}

.background {
  position: absolute;
  top: rem(-10px);
  left: rem(-7px);
  width: rem(200px);
  transform: rotate(13.614deg);

  @include media-up(md) {
    left: rem(-16px);
    width: rem(250px);

    .artist-card--big & {
      top: rem(-20px);
      left: rem(-26px);
      width: rem(400px);
    }
  }

  @include media-up(lg) {
    top: rem(-20px);
    left: rem(-13px);
    width: rem(350px);

    .artist-card--big & {
      left: rem(-44px);
      width: rem(500px);
    }
  }
}

.title {
  margin-bottom: rem(8px);

  @include media-up(md) {
    margin-bottom: rem(12px);

    .artist-card--big & {
      margin-bottom: rem(16px);
    }
  }

  @include media-up(lg) {
    margin-bottom: rem(16px);

    .artist-card--big & {
      margin-bottom: rem(24px);
    }
  }
}

.artist-name {
  @include font-size(
    (
      xs: 20px 1,
      md: 22px 1.12705,
      lg: 26px 1.07692,
      xxl: 32px 1.15556
    )
  );
  margin-right: rem(4px);
  font-weight: 700;
  letter-spacing: -0.01em;

  @include media-up(md) {
    margin-right: rem(8px);
  }

  .artist-card--big & {
    @include font-size(
      (
        md: 32px 1.1875,
        lg: 38px 1.10526,
        xxl: 45px 1.1875
      )
    );
  }
}

.country {
  position: relative;
  top: rem(1px);
  display: inline-block;
  vertical-align: top;

  @include media-up(sm) {
    display: inline;
  }

  @include media-up(md) {
    top: rem(2px);

    .artist-card--big & {
      top: rem(4px);
    }
  }

  @include media-up(lg) {
    .artist-card--big & {
      top: rem(3px);
    }
  }

  @include media-up(xxl) {
    top: rem(3px);

    .artist-card--big & {
      top: rem(6px);
    }
  }
}

.image-container {
  aspect-ratio: 140/78;
  margin-bottom: rem(24px);
  overflow: hidden;

  @include media-up(md) {
    aspect-ratio: 192.46/103.74;

    .artist-card--big & {
      aspect-ratio: 296/159;
      margin-bottom: rem(32px);
    }
  }

  @include media-up(lg) {
    aspect-ratio: 277/151;
    margin-bottom: rem(48px);

    .artist-card--big & {
      aspect-ratio: 376/211;
      margin-bottom: rem(64px);
    }
  }
}
</style>
